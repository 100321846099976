<template>
  <div class="voucher">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/gift-vouchers">Gift Vouchers</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.number }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="pl-lg-9 px-sm-7 pt-sm-5 py-3 px-3 d-flex align-center">
        <div>
          <div class="text-h6 font-weight-bold">{{ data.number }}</div>
          <div class="text--text" v-if="data.product_order">
            Submitted at:
            {{
              data.product_order.submitted_at ? new Date(data.product_order.submitted_at).toLocaleString('en-GB').split('/').join('.') : ''
            }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <div :class="colorStatus(data.status)" class="status-block ml-5">{{ data.status }}</div>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 pt-sm-5 py-3 px-3 item-content">
        <div v-if="data.product_order">
          <div
            v-if="data.product_order.product"
            class="d-flex flex-sm-row flex-column-reverse mb-5 produst-card link"
            @click="openProduct(data.product_order.product.id)"
          >
            <div class="d-flex flex-column" style="max-width: 500px">
              <div class="font-weight-bold primary--text mb-4 vendor-name" @click.stop="openVendor" v-if="data.product_order">
                {{ data.product_order.vendor.title_en }}
              </div>
              <div class="text-h6 font-weight-bold">{{ data.product_order.product.title_en }}</div>
              <div class="text--text mt-1 three-rows mb-4">{{ data.product_order.product.description_en }}</div>
              <v-spacer></v-spacer>
              <div class="font-weight-bold">SAR {{ data.product_order.price / 100 }}</div>
            </div>
            <v-spacer></v-spacer>
            <v-img
              v-if="data.product_order.product.picture"
              class="ml-sm-5 mt-sm-5 mx-auto"
              height="160"
              width="100%"
              max-width="160"
              cover
              :src="data.product_order.product.picture.url"
            ></v-img>
          </div>
        </div>
        <div v-if="data.person || data.receiver">
          <div class="d-flex flex-sm-row flex-column mb-5">
            <div :class="{ 'mr-sm-3 mb-sm-0 mb-5': data.receiver }" class="width100" v-if="data.person">
              <div class="font-weight-bold mb-2">Customer details</div>
              <v-card outlined class="px-3 py-4 rounded-lg">
                <div class="d-flex align-center mb-2">
                  <div class="mr-4">Full Name</div>
                  <v-spacer></v-spacer>
                  <div class="text--text">{{ data.person.first_name }} {{ data.person.last_name }}</div>
                </div>
                <div class="d-flex align-center mb-2">
                  <div class="mr-4">Phone number</div>
                  <v-spacer></v-spacer>
                  <div class="text--text">{{ data.person.phone_number }}</div>
                </div>
                <div class="d-flex align-center">
                  <div class="mr-4">Email</div>
                  <v-spacer></v-spacer>
                  <div class="text--text">{{ data.person.email }}</div>
                </div>
              </v-card>
            </div>
            <div :class="{ 'ml-sm-3': data.person }" class="width100" v-if="data.receiver">
              <div class="font-weight-bold mb-2">Recipient details</div>
              <v-card outlined class="px-3 py-4 rounded-lg">
                <div class="d-flex align-center mb-2">
                  <div class="mr-4">Full Name</div>
                  <v-spacer></v-spacer>
                  <div class="text--text">{{ data.receiver.first_name }} {{ data.receiver.last_name }}</div>
                </div>
                <div class="d-flex align-center mb-2">
                  <div class="mr-4">Phone number</div>
                  <v-spacer></v-spacer>
                  <div class="text--text">{{ data.receiver.phone_number }}</div>
                </div>
                <div class="d-flex align-center">
                  <div class="mr-4">Email</div>
                  <v-spacer></v-spacer>
                  <div class="text--text">{{ data.receiver.email }}</div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
        <div v-if="data.product_order">
          <div class="font-weight-bold mb-2" v-if="data.product_order.delivery">Delivery info</div>
          <v-card outlined class="px-3 py-4 mb-5 rounded-lg" v-if="data.product_order.delivery">
            <div class="d-flex align-center mb-2">
              <div class="mr-4">Country, city</div>
              <v-spacer></v-spacer>
              <div class="text--text">{{ data.product_order.delivery.country.name }}, {{ data.product_order.delivery.city.name }}</div>
            </div>
            <div class="d-flex align-center mb-2">
              <div class="mr-4">Address</div>
              <v-spacer></v-spacer>
              <div class="text--text">{{ data.product_order.delivery.address }}</div>
            </div>
            <div class="d-flex align-center mb-2">
              <div class="mr-4">Delivery date</div>
              <v-spacer></v-spacer>
              <div class="text--text">
                {{ data.product_order.delivery.date.split('-').reverse().join('.') }}
                {{ data.product_order.delivery.time_from }}
                {{ data.product_order.delivery.time_to ? `- ${data.product_order.delivery.time_to}` : '' }}
              </div>
            </div>
            <div class="d-flex align-center">
              <div class="mr-4">Delivery fee</div>
              <v-spacer></v-spacer>
              <div class="text--text">SAR {{ data.product_order.delivery.fee / 100 }}</div>
            </div>
          </v-card>
        </div>
        <div class="font-weight-bold mb-2" v-if="data.email_sent_at">Email sending date/time</div>
        <v-card outlined class="px-3 py-4 mb-5 rounded-lg" v-if="data.email_sent_at">
          <div class="d-flex align-center mb-2">
            <div class="mr-4">Scheduled</div>
            <v-spacer></v-spacer>
            <div class="text--text">{{ data.email_sent_at.split('-').reverse().join('.') }}</div>
          </div>
        </v-card>
        <div class="font-weight-bold mb-2" v-if="data.wishes">Wishes text</div>
        <v-card outlined class="px-3 py-4 mb-5 rounded-lg" min-height="156" v-if="data.wishes">{{ data.wishes }}</v-card>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 px-5 pt-6">
        <div class="d-flex align-center mb-4" v-if="data.amount">
          <div>
            <span class="font-weight-bold text--text">Voucher amount</span>
            <span class="gray--text"> (VAT {{ data.amount_vat_included ? 'incl.' : 'not incl.' }})</span>
          </div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold">SAR {{ data.amount / 100 }}</div>
        </div>
        <div class="d-flex align-center mb-4" v-if="data.amount_vat">
          <div>
            <span class="font-weight-bold text--text">Voucher VAT</span>
            <span class="gray--text ps-1" v-if="data.amount_vat_type != 'percent'">(Fixed)</span>
            <span class="gray--text ps-1" v-else>({{ data.amount_vat_value / 100 }}%)</span>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="font-weight-bold">SAR {{ data.amount_vat / 100 }}</span>
          </div>
        </div>
        <div v-if="data.product_order">
          <div class="d-flex align-center mb-4" v-if="data.product_order.product">
            <div>
              <span class="font-weight-bold text--text">{{ data.product_order.product.title_en }}</span>
              <span class="gray--text"> (VAT {{ data.product_order.vat_included ? 'incl.' : 'not incl.' }})</span>
            </div>
            <v-spacer></v-spacer>
            <div class="font-weight-bold">SAR {{ data.product_order.price / 100 }}</div>
          </div>
        </div>
        <div class="d-flex align-center mb-4" v-if="data.product_order">
          <div>
            <span class="font-weight-bold text--text">Product VAT</span>
            <span class="gray--text ps-1" v-if="data.product_order.vat_type != 'percent'">(Fixed)</span>
            <span class="gray--text ps-1" v-else>({{ data.product_order.vat_value / 100 }}%)</span>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="font-weight-bold">SAR {{ data.product_order.vat / 100 }}</span>
          </div>
        </div>
        <div class="d-flex align-center mb-4" v-if="data.dw_refill_code">
          <div class="font-weight-bold text--text">Digital Wallet refill code</div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold">{{ data.dw_refill_code }}</div>
        </div>
        <div v-if="data.product_order">
          <div class="d-flex align-center mb-4" v-if="data.product_order.delivery">
            <div>
              <span class="font-weight-bold text--text">Delivery fee</span>
              <span class="gray--text"> (VAT {{ data.product_order.delivery.fee_vat_included ? 'incl.' : 'not incl.' }})</span>
            </div>
            <v-spacer></v-spacer>
            <div class="font-weight-bold">SAR {{ data.product_order.delivery.fee / 100 }}</div>
          </div>
          <div class="d-flex align-center mb-4" v-if="data.product_order.delivery">
            <div>
              <span class="font-weight-bold text--text">Delivery fee VAT</span>
              <span class="gray--text ps-1" v-if="data.product_order.delivery.fee_vat_type != 'percent'">(Fixed)</span>
              <span class="gray--text ps-1" v-else>({{ data.product_order.delivery.fee_vat_value / 100 }}%)</span>
            </div>
            <v-spacer></v-spacer>
            <div>
              <span class="font-weight-bold">SAR {{ data.product_order.delivery.fee_vat / 100 }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-center mb-4" v-if="data.vat_total">
          <div class="font-weight-bold text--text">VAT</div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold">SAR {{ data.vat_total / 100 }}</div>
        </div>
      </div>
      <v-divider class="dashad"></v-divider>
      <div class="pl-lg-9 px-sm-7 pt-4 px-3 d-flex align-center" :class="attachments.length ? 'pb-1' : 'pb-10'">
        <div class="text-h6 font-weight-bold text--text">Total</div>
        <v-spacer></v-spacer>
        <div class="text-h6 font-weight-bold">SAR {{ data.total / 100 }}</div>
      </div>
      <div class="pl-lg-9 px-sm-7 pb-10 px-3 d-flex align-center" v-if="attachments.length">
        <v-card
          v-for="item in attachments"
          :key="item.id"
          rounded="lg"
          flat
          outlined
          class="d-flex align-center mt-4 width100 accent att-card"
          min-height="72"
          max-width="500"
        >
          <div class="d-flex align-center width100 pe-5 ps-4" @click="$vuetify.breakpoint.xs ? downloadFile(item) : false">
            <div class="d-flex align-center me-3">
              <img :src="getIcon(item.type)" height="42" width="42" alt="file" />
              <div class="ms-4">
                <div
                  :class="$vuetify.breakpoint.xs ? 'text-body-2' : ''"
                  class="font-weight-bold text-truncate"
                  :style="`max-width: ${$vuetify.breakpoint.xs ? '50vw' : '200px'}`"
                >
                  {{ item.title }}
                </div>
                <div :class="$vuetify.breakpoint.xs ? 'text-body-2' : ''" class="gray--text">
                  {{ sizeFile(item.size_bytes) }}
                </div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              @click="downloadFile(item)"
              depressed
              outlined
              color="primary"
              class="d-sm-inline-flex d-none rounded ms-2"
              width="130"
              height="38"
            >
              <img src="@/assets/icon/download.svg" alt="download" /><span class="text-body-2">Download</span>
            </v-btn>
            <!-- <v-btn @click="removeItem" depressed outlined color="error" class="rounded" width="48" height="38" icon>
              <img class="error-filter" src="@/assets/icon/delete.svg" alt="icon" />
            </v-btn> -->
          </div>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch('getVoucherItem');
    this.$store.dispatch('getVoucherItemAttachments');
  },
  methods: {
    colorStatus(s) {
      if (s == 'paid') {
        return 'primary--text primary lighten-3';
      } else if (s == 'created') {
        return 'warning--text warning lighten-5';
      } else if (s == 'delivered' || s == 'redeemed') {
        return 'success--text success lighten-5';
      } else if (s == 'expired') {
        return 'error--text error lighten-5';
      } else {
        return 'gray--text gray lighten-4';
      }
    },
    openProduct(id) {
      this.$router.push(`/gift-vendors/${this.data.product_order.vendor.id}/products?product=${id}`);
    },
    openVendor() {
      this.$router.push(`/gift-vendors/${this.data.product_order.vendor.id}/general`);
    },
    removeItem() {
      this.$store.dispatch('removeVoucherItemAttachments');
    },
    getIcon(icon) {
      return require(`@/assets/icon/file/${icon}.svg`);
    },
    sizeFile(s) {
      if (s <= 1024) {
        return `${s} B`;
      } else if (s / 1024 <= 1024) {
        return `${(s / 1024).toFixed(2)} Kb`;
      } else {
        return `${(s / 1024 / 1024).toFixed(2)} Mb`;
      }
    },
    downloadFile(item) {
      const link = document.createElement('a');
      link.download = item.title;
      link.target = '_blank';
      link.href = item.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  computed: {
    data() {
      return this.$store.getters.voucher;
    },
    attachments() {
      return this.$store.getters.voucherAttachments;
    },
  },
  destroyed() {
    this.$store.dispatch('setVoucherItem');
    this.$store.dispatch('setVoucherItemAttachments');
  },
};
</script>

<style lang="scss">
.voucher {
  .produst-card {
    padding: 20px;
    background: var(--v-gray-lighten4);
    border: 1px solid var(--v-gray-lighten1);
    border-radius: 10px;
    .v-image {
      border-radius: 10px;
    }
  }
  .status-block {
    width: 100px;
    height: 42px;
    line-height: 42px;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    border-radius: 6px;
  }
  .vendor-name {
    width: max-content;
  }
  .vendor-name:hover::after {
    content: '';
    margin-top: -1px;
    display: block;
    height: 1px;
    background: var(--v-primary-base);
    position: relative;
    width: 100%;
  }
  .att-card {
    border: 1px solid var(--v-gray-lighten2) !important;
    .v-btn.primary--text {
      background: var(--v-primary-lighten3);
    }
    .v-btn.error--text {
      background: var(--v-error-lighten5);
    }
  }
}
</style>
